<template>
  <div v-if="project">
    <Loader :visible="loading" />
    <v-card class="px-6 py-8 mb-2">
      <div class="input-label d-flex justify-space-between">
        Nombre del proyecto<v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('GeneralData')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div class="text-profile roboto">
        {{ project.name }}
      </div>
      <br />
      <div class="input-label">Responsable</div>
      <div class="text-profile roboto">
        {{ project.owner }}
      </div>
    </v-card>
    <v-card class="px-6 py-8 mb-4">
      <div class="input-label d-flex justify-space-between">
        Brecha a acortar
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('GeneralData')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div class="text-profile roboto">
        {{ project.summary }}
      </div>
      <br />
      <div class="input-label">Objetivos del negocio</div>
      <div class="text-profile roboto">
        {{ project.businessGoal }}
      </div>
      <br />
      <div class="input-label">Objetivos del proyecto</div>
      <div class="text-profile roboto">
        {{ project.proyectGoal }}
      </div>
      <br />
      <div class="input-label">Alcance del proyecto</div>
      <div class="text-profile roboto">
        {{ project.scope }}
      </div>
    </v-card>
    <v-card class="px-6 py-8 mb-4">
      <div class="input-label">Mejoras Cuantitativas</div>
      <div class="text-profile roboto">
        {{ project.quantitativeImprovements }}
      </div>
      <br /><br />
      <div class="input-label">Mejoras Cualitativas</div>
      <div class="text-profile roboto">
        {{ project.qualitativeImprovements }}
      </div>
    </v-card>
    <v-card class="px-6 py-8 mb-4">
      <div class="input-label d-flex justify-space-between">
        Indicadores de éxito
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('Improvements')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <v-chip v-for="evc in project.evaluationCriteria" :key="evc.id" class="px-4 py-4 mr-2 my-2">
        {{ evc.name }}
      </v-chip>
      <br /><br />
      <div class="input-label">Presupuesto Estimado</div>
      <div class="text-profile roboto">
        {{ project.estimatedBudged !== null ? project.estimatedBudged.value : "Sin registrar" }}
      </div>
    </v-card>
    <v-card class="px-6 py-8 mb-4">
      <div class="input-label d-flex justify-space-between">
        Duración estimada
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('Improvements')">
          mdi-pencil-outline
        </v-icon>
      </div>
      <div v-if="project.period && project.period.name" class="text-profile roboto">
        {{ project.period.name }}
      </div>
    </v-card>
    <v-card class="px-6 py-8 mb-4">
      <div class="input-label d-flex justify-space-between">
        Requisitos Técnicos
        <v-icon v-if="edit" class="edit-icon" color="tway-violet" @click="goToStep('Requeriments')">
          mdi-pencil-outline
        </v-icon>
        <v-icon
          v-else
          class="edit-icon"
          color="tway-violet"
          @click="download(project.technicalRequirements.id, project.technicalRequirements.name)"
        >
          mdi-download-outline
        </v-icon>
      </div>
      <div v-if="project && project.technicalRequirements" class="text-profile roboto">
        {{ project.technicalRequirements.name }} ({{ project.technicalRequirements.size / 1000000 }}
        MB)
      </div>
      <div v-if="project.aditionalAttachment">
        <br />
        <div class="input-label d-flex justify-space-between">
          Anexos
          <v-icon
            v-if="!edit"
            class="edit-icon"
            color="tway-violet"
            @click="download(project.aditionalAttachment.id, project.aditionalAttachment.name)"
          >
            mdi-download-outline
          </v-icon>
        </div>
        <div v-if="project && project.aditionalAttachment" class="text-profile roboto">
          {{ project.aditionalAttachment.name }} ({{ project.aditionalAttachment.size / 1000000 }}
          MB)
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import CallForTenderService from "@/services/callForTenderService";
import Loader from "@/components/Loader";

export default {
  name: "ProjectSummary",
  props: {
    project: { type: Object, default: null },
    edit: Boolean,
  },
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    goToStep(step) {
      this.$router.push(step);
    },
    download(attachmentId, alternativeName) {
      this.loading = true;
      CallForTenderService.downloadAttachment(attachmentId, alternativeName)
        .catch((error) => this.$log.error(error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.v-application .primary--text {
  caret-color: rgb(150, 82, 223);
  color: rgb(150, 82, 223) !important;
}

.text-profile {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #757575;
}

.terms-and-conditions >>> .v-label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.edit-icon {
  cursor: pointer;
}
</style>
