<template>
  <div>
    <div class="text-h5 text-md-h4 text-center mb-4 title tway-gray-dark--text">Detalle Descripción de Proyecto</div>
    <v-row>
      <v-col offset-md="2" md="8" sm="12">
        <project-summary :project="project" />
        <div class="d-flex justify-center align-center my-12">
          <v-btn
            class="text-uppercase text-body-1 roboto lower mr-6"
            rounded
            text
            x-small
            color="deep-purple accent-4"
            @click="$router.back()"
          >
            Volver
          </v-btn>
          <v-btn
            v-if="project.applicationStatus && project.applicationStatus.id === projectStatus.noApplication.id"
            large
            depressed
            rounded
            class="tway-violet white--text roboto elevation-3 ml-6"
            @click="goToProject"
          >
            COMENZAR POSTULACIÓN DE PROYECTO
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CallForTenderService from "@/services/callForTenderService";
import ProjectSummary from "@/components/projects/ProjectSummary";
import projectApplicationStatusJSON from "@/utils/projectApplicationStatus";

export default {
  name: "ProjectDescription",
  components: {
    ProjectSummary,
  },
  mounted() {
    this.getProject(this.$route.params.projectId);
  },
  data() {
    return {
      project: null,
      projectStatus: projectApplicationStatusJSON,
    };
  },
  methods: {
    getProject(id) {
      CallForTenderService.getSpecialistProject(id)
        .then((p) => {
          if (!p) {
            this.$router.push("my-projects");
          }
          this.project = p;
        })
        .catch((err) => this.$log.error(err));
    },
    goToProject() {
      this.$router.push(`/apply-for-project/project=${this.project.id}`);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 16.5pt !important;
}
</style>
